import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { createBookStock, deleteOneBookStock, getAllBookStocks, getOneBookStock, updateBookStock, } from './actions';
var initialState = {
    bookStocks: { docs: [], meta: {} },
    bookStock: null,
    status: IStatus.IDLE,
};
var slice = createSlice({
    name: 'bookStocks',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // CREATE ONE
        builder
            .addCase(createBookStock.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createBookStock.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.bookStocks = action.payload.data;
        })
            .addCase(createBookStock.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // UPDATE ONE
        builder
            .addCase(updateBookStock.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateBookStock.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.bookStock = action.payload.data;
        })
            .addCase(updateBookStock.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ALL
        builder
            .addCase(getAllBookStocks.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllBookStocks.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.bookStocks = action.payload;
        })
            .addCase(getAllBookStocks.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET ONE
        builder
            .addCase(getOneBookStock.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneBookStock.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.bookStock = action.payload.data;
        })
            .addCase(getOneBookStock.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // DELETE ONE
        builder
            .addCase(deleteOneBookStock.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteOneBookStock.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.bookStocks.docs = state.bookStocks.docs.filter(function (bookStock) { return bookStock._id !== action.meta.arg; });
        })
            .addCase(deleteOneBookStock.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
